<template>
  <transition
    enter-active-class="animated fadeIn faster"
    leave-active-class="animated fadeOut faster"
    mode="out-in"
  >
    <LoginFormUser v-if="step === 1" key="1" @attempt-login="attemptLogIn" />
    <LoginSetPassword
      v-if="step === 2"
      key="2"
      @done="attemptLogIn"
      :generated-password="form.password"
      :token="user.token"
    />
    <LoginSelectBranch
      v-if="step === 3"
      key="3"
      v-bind="{ user }"
      @complete="complete"
    />
  </transition>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { RESET, SET_STEP } from '../stores/login.mutation-types'
import { confirmBranch } from 'modules/login/services/login.service'
export default {
  name: 'LoginForm',
  components: {
    LoginFormUser: () => import('./LoginFormUser'),
    LoginSelectBranch: () => import('./LoginSelectBranch'),
    LoginSetPassword: () => import('./LoginSetPassword')
  },
  data: () => ({
    error: null
  }),
  computed: {
    ...mapState({
      form: state => state.login.form,
      step: state => state.login.step,
      user: state => state.login.user
    })
  },
  methods: {
    ...mapMutations({
      setStep: `login/${SET_STEP}`,
      reset: `login/${RESET}`
    }),
    async attemptLogIn() {
      const branches = this.user.branches
      const valid = branches?.filter(({ type }) => type !== 'experian')
      if (valid?.length === 1) {
        const { jwt } = await confirmBranch({
          token: this.user.token,
          orgId: valid[0].id
        })
        return this.complete({ jwt })
      }
      return this.setStep(3)
    },
    async complete({ jwt }) {
      this.$store.commit('auth/setToken', jwt)
      try {
        await this.$store.dispatch('auth/getUser')
        this.$router.push({ name: 'root' }).catch(() => {})
      } catch (err) {
        this.$handleError(err).notify('danger')
      } finally {
        this.reset()
      }
    }
  }
}
</script>
